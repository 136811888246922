import React, { Component } from 'react';

import styles from './H3.module.css';

export class H3 extends Component {
	render() {
		return (
			<h3 className={styles.title_h3}>{this.props.children}</h3>
		);
	}
}

export default H3