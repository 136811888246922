import React, { Component } from 'react';

import P from '../../components/atoms/P/P';
import Container from '../../components/atoms/Container/Container';
import H1 from '../../components/atoms/H1/H1';
import H2 from '../../components/atoms/H2/H2';
import H3 from '../../components/atoms/H3/H3';
import H4 from '../../components/atoms/H4/H4';
import A from '../../components/atoms/A/A';
import Img from '../../components/atoms/Img/Img';
import Grid from '../../components/atoms/Grid/Grid';
import GridElement from '../../components/atoms/GridElement/GridElement';
import ChatBot from '../../components/molecules/ChatBot/ChatBot';

export class UeberMich extends Component {
	render() {
		return (
			<Container>
				<Grid>
				<GridElement layout="c4">
					<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/portrait-2_iM2xwcipZ?ik-sdk-version=javascript-1.4.3&updatedAt=1655760891060" alt="Portrait von Karl Hofmann"/>
					<H1>Hallo<br/>Ich bin Karl.</H1>
				</GridElement>
				<GridElement layout="c3">
					<P>Ich bin Mediamatiker & Kaffeetrinker. Ich mag Weissraum, knallige Farben und Schweizer Schriften. Im Internet mag ich es wenn Seiten schnell laden und auf den Menschen sowie das Gerät zugeschnitten sind. Am leichtesten beeindruckst du mich mit einem elektronischen Gadget oder einem guten Kaffee.</P>
				</GridElement>

				<GridElement layout="c4">
					<H2>23 Jahre Erfahrung.</H2>
				</GridElement>
				<GridElement layout="c4">
					<H3>Seit Juli 2021</H3>
					<P>Mitarbeiter Kommunikation & Marketing<br />bei KOSMOS Kultur AG (70%)</P>
					<P>Graphic & Multimedia Designer<br />bei back on earth GmbH (30%)</P>
				</GridElement>
				<GridElement layout="c4">
					<H4>Mai 2020 – Juni 2021</H4>
					<P>Social Media Redaktor (Migusto)<br />bei Migros-Genossenschafts-Bund (80%)</P>
					<P>Graphic & Multimedia Designer<br />bei back on earth GmbH (20%)</P>
				</GridElement>
				<GridElement layout="c4">
					<H4>Aug. 2019 - April 2020</H4>
					<P>Layout & Social Media Redaktor (iMpuls)<br />bei Migros-Genossenschafts-Bund</P>
				</GridElement>
				<GridElement layout="c4">
					<H4>2015 - 2019</H4>
					<P>Mediamatiker EFZ in Ausbildung (mit BMS)<br />bei Migros-Genossenschafts-Bund & ZLI</P>
				</GridElement>
				<GridElement layout="c4">
					<H4>2012 - 2015</H4>
					<P>Bezirksschule Baden</P>
				</GridElement>
				<GridElement layout="c4">
					<H4>2010 - 2012</H4>
					<P>Sekundarschule Baden</P>
				</GridElement>
				<GridElement layout="c4">
					<H4>2005 - 2010</H4>
					<P>Primarschule Ennetbaden</P>
				</GridElement>
				<GridElement layout="c4">
					<H4>22. Feb. 1999</H4>
					<P>Geboren in Stuttgart</P>
				</GridElement>
				
				<GridElement layout="c4">
					<H2>Freizeit</H2>
				</GridElement>
				<GridElement layout="c4">
					<H3>Seit Juni 2021</H3>
					<P>Vorstandsmitglied <A href="https://www.zli.ch/uber-uns/organisation" target="_blank">Zürcher Lehrbetriebsverband ICT</A></P>
				</GridElement>
				<GridElement layout="c4">
					<H3>Seit Sept. 2018</H3>
					<P>Leitung <A href="https://www.zli.ch/berufsverband-oda/mitwirken" target="_blank">Fachgruppe ICT-Berufslehren U25</A></P>
				</GridElement>
				<GridElement layout="c4">
					<H3>Seit März 2022</H3>
					<P>Prüfungsexperte <A href="https://pk19.ch/" target="_blank">PK19</A></P>
				</GridElement>
				<GridElement layout="c4">
					<H3>Seit März 2022</H3>
					<P>Vorstandsmitglied <A href="https://www.kleinlautfestival.ch/" target="_blank">Verein kleinLaut Festival</A><br />Ressort Finanzen</P>
				</GridElement>
				

				<GridElement layout="c4">
					<H2>Darauf bin ich stolz.</H2>
				</GridElement>
				<GridElement layout="c4">
					<H3>Sept. 2019</H3>
					<P>Gründung back on earth GmbH, Agentur für Video & Design</P>
				</GridElement>
				<GridElement layout="c4">
					<H3>Sept. 2018</H3>
					<P>SwissSkills 4. Rang Trade Mediamatik</P>
				</GridElement>
				<GridElement layout="c4">
					<H3>März 2018</H3>
					<P>Regionalmeister Mediamatik Kanton Zürich</P>
				</GridElement>

				{/*<GridElement layout="c4">
					<H2>Der Karl-Bot</H2>
					<H4>Lust ein bisschen zu plaudern?<br /> Hol dir einen Kaffee & schreibe mit meinem virtuellen Ich.</H4>
				</GridElement>
					<ChatBot />*/}
				</Grid> 
			</Container>	
		);
	}
}

export default UeberMich