import React, { Component } from 'react';

import './App.css';

import { BrowserRouter, Route, Switch } from "react-router-dom";

import LogoHeader from './components/molecules/LogoHeader/LogoHeader';
import Footer from './components/molecules/Footer/Footer';
import BandportraitAlPride from './pages/Portfolio/BandportraitAlPride/BandportraitAlPride';
import KursprogrammFilzquadrat from './pages/Portfolio/KursprogrammFilzquadrat/KursprogrammFilzquadrat';
import AnimationsvideoDimmi from './pages/Portfolio/AnimationsvideoDimmi/AnimationsvideoDimmi';
import WassermarkeLibertas from './pages/Portfolio/WassermarkeLibertas/WassermarkeLibertas';
import AusstellungWohnenBeWohnen from './pages/Portfolio/AusstellungWohnenBeWohnen/AusstellungWohnenBeWohnen';
import Sommersause from './pages/Portfolio/Sommersause/Sommersause';
import personalisierteVideos from './pages/Portfolio/personalisierteVideos/personalisierteVideos';
import KeiPartyBier from './pages/Portfolio/KeiPartyBier/KeiPartyBier';
import Revalur from './pages/Portfolio/Revalur/Revalur';
import ErzAwarenessvideos from './pages/Portfolio/ErzAwarenessvideos/ErzAwarenessvideos';
import Portfolio from './pages/Portfolio/Portfolio';
import Home from './pages/Home/Home';
import UeberMich from './pages/UeberMich/UeberMich';

class App extends Component {
  render() {
    return (
    	<BrowserRouter>
    		<div className="App">
    			<div className="RealContent">
	        	<LogoHeader />
	    		<Switch>
	    			<Route path="/" exact component={Home} />
		    		<Route path="/portfolio" exact component={Portfolio} />
		    			<Route path="/portfolio/erz-awarenessvideos" exact component={ErzAwarenessvideos} />
		    			<Route path="/portfolio/personalisierte-videos" exact component={personalisierteVideos} />
		    			<Route path="/portfolio/kei-party-bier" exact component={KeiPartyBier} />
		    					<Route path="/portfolio/zhack-branding" exact component={BandportraitAlPride} />
		    					<Route path="/portfolio/imagekampagne-migros-magazin" exact component={BandportraitAlPride} />
		    			<Route path="/portfolio/revalur" exact component={Revalur} />
		    					<Route path="/portfolio/schiae-scho" exact component={BandportraitAlPride} />
		    			<Route path="/portfolio/sommersause" exact component={Sommersause} />
		    					<Route path="/portfolio/fontanone" exact component={Sommersause} />
		    					<Route path="/portfolio/newsletter-migros-magazin" exact component={Sommersause} />

		    			<Route path="/portfolio/bandportrait-al-pride" exact component={BandportraitAlPride} />
		    			<Route path="/portfolio/kursprogramm-filzquadrat" exact component={KursprogrammFilzquadrat} />
		    			<Route path="/portfolio/animationsvideo-dimmi" exact component={AnimationsvideoDimmi} />
		    			<Route path="/portfolio/wassermarke-libertas" exact component={WassermarkeLibertas} />
		    			<Route path="/portfolio/ausstellung-wohnen-be-wohnen" exact component={AusstellungWohnenBeWohnen} />
		    		<Route path="/ueber-mich" exact component={UeberMich} />
	    		</Switch>
	    		</div>
	    		<Footer />	
    		</div>
    	</BrowserRouter>
    );
  }
}

export default App;