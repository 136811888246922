import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H1 from '../../../components/atoms/H1/H1';
import H2 from '../../../components/atoms/H2/H2';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class KursprogrammFilzquadrat extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/Kursprogramm_Filzquadrat/filzquadrat-kursprogramm-cover_Hy_c8LLmE.jpg"/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="Kursprogramm 18/19"
                client="Stefanie Hofmann, Filzquadrat"
                year="2018"
                competence="Print"
                collaboration={<React.Fragment><A href="https://filzquadrat.ch/kurse" target="_blank">Filzquadrat</A></React.Fragment>}
              />
            </GridElement>

            <GridElement layout="c2_1">
              <H1>Massenweise Unikate.</H1>
            </GridElement>
            
            <GridElement layout="c2_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Kursprogramm_Filzquadrat/filzquadrat-kursprogramm-stapel_Bk5t8U8XE.jpg" alt="Kursporgramme Filzquadrat"/>
            </GridElement>

            <GridElement layout="c2">
              <P>Das Kursprogramm repräsentiert sowohl das Material und das Handwerk des Filzens, als auch die Werkstatt im Oederlin-Areal.</P>
            </GridElement>
            <GridElement layout="c2_1">
              <P>Es geht nicht um Perfektion. Jedes einzelne Kursprogramm wird durch seine Machart zum Einzelstück.</P>
            </GridElement>


            <GridElement layout="c3">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Kursprogramm_Filzquadrat/filzquadrat-kursprogramm-aufgeklappt_ryDFLIIm4.jpg" alt="Aufgeklapptes Kursprogramm"/>
            </GridElement>
            <GridElement layout="c3">
              <P>Bei der Gestaltung war vor allem die Übersichtlichkeit und eine für die Zielgruppe ansprechendes Design herausfordernd. </P>
            </GridElement>

            <GridElement layout="c2_2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Kursprogramm_Filzquadrat/filzquadrat-kursprogramm-riso-drucker_SyisLLI7N.jpg" alt="Smartphone NFC Tag"/>
            </GridElement>
            <GridElement layout="c2">
              <Img src="https://ik.imagekit.io/kaffeekraft/filzquadrat-kursprogramm-werden-gedruckt_rkvXujsmV.jpg" alt="Smartphone NFC Tag"/>
            </GridElement>
            <GridElement layout="c2_2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Kursprogramm_Filzquadrat/filzquadrat-kursprogramm-closeup_B1TcLILm4.jpg" alt="AlPride Spotify Codes im Magazin"/>
            </GridElement>

            <GridElement layout="c2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Kursprogramm_Filzquadrat/filzquadrat-kursprogramm-faltmaschine_rkOjL8IXN.jpg" alt="AlPride Spotify Codes im Magazin"/>
            </GridElement>
            <GridElement layout="c2_2">
              <P>Gedruckt ist das zweifarbige Kursprogramm mit einem Risographen im Werkbereich des Dynamo Zürich. Auch für die Wickelfalzung stand dort eine Maschine bereit. </P>
            </GridElement>            

            <GridElement layout="c2_1">
              <H2>Ein Kursprogramm, das auffällt und wiedererkennung schafft.</H2>
            </GridElement>
          </Grid>
        </Container>
    );
  }
}

export default KursprogrammFilzquadrat;