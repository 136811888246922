import React, { Component } from 'react';

import styles from './Grid.module.css';

export class Grid extends Component {
	render() {
		return (
			<div className={styles.grid}>
				{this.props.children}
			</div>
		);
	}
}

export default Grid