import React, { Component } from 'react';

import styles from './H1.module.css';

export class H1 extends Component {
	render() {
		return (
			<h1 className={styles.title_h1}>{this.props.children}</h1>
		);
	}
}

export default H1