import React, { Component } from 'react';

import styles from './ProjectTable.module.css'

import H3 from '../../atoms/H3/H3';
import H4 from '../../atoms/H4/H4';

export class ProjectTable extends Component {
    render() {
        return (
            <table>
                <tbody>
                    <tr>
                        <th colSpan="3" className="title"><H3>{this.props.title}</H3></th>
                        {/*<th colSpan="1" className="client"><H3>{this.props.client}</H3></th>*/}
                    </tr>
                    <tr>
                        <th className={styles.year}><H3>{this.props.year}</H3></th>
                        <td className="competence"><H4>{this.props.competence}</H4></td>
                        <td className="collaboration">
                            <H4>
                                {this.props.collaboration}
                            </H4>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default ProjectTable