import React, { Component } from 'react';

import styles from './GridElement.module.css';

export class GridElement extends Component {
	render() {
		let layout = styles.c1;
			switch (this.props.layout) {
				case "c4":
					layout = styles.c4;
					break;
				case "c3":
					layout = styles.c3;
					break;
				case "c3_1":
					layout = styles.c3_1;
					break;
				case "c2":
					layout = styles.c2;
					break;
				case "c2_1":
					layout = styles.c2_1;
					break;
				case "c2_2":
					layout = styles.c2_2;
					break;
				case "c1":
					layout = styles.c1;
					break;
				case "c1_1":
					layout = styles.c1_1;
					break;
				case "c1_2":
					layout = styles.c1_2;
					break;
				case "c1_3":
					layout = styles.c1_3;
					break;
				default:
					layout = styles.c1;
			}			

		return (
			<div className={layout}>
				{this.props.children}
			</div>
		);
	}
}

export default GridElement