import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import H3 from '../../../components/atoms/H3/H3';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class Sommersause extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/header-sommersause_WxAtadvQp"/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="WERKK Sommersause"
                client="Kulturlokal WERKK"
                year="2019"
                competence="Branding, Print, Online, Signaletik"
                collaboration={<React.Fragment><A href="https://werkk-baden.ch" target="_blank">WERKK KULTURLOKAL</A></React.Fragment>}
              />
            </GridElement>
            <GridElement layout="c2_2">
              <H2>Eine Riesensause.</H2>
            </GridElement>
            <GridElement layout="c3">
              <P>Ein grosses, 7-tägiges Festival sollte vor der Sommerpause noch einmal richtig Lärm machen. Genauso laut und bunt wurde auch die visuelle Identität des Festivals.</P>
            </GridElement>
            <GridElement layout="c4">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/sommersause-werkk_kj_B4nZFMy.jpg" alt=""/>
            </GridElement>

            <GridElement layout="c2">
              <P><br/>Das Keyvisual bestand aus einer «Sausenflasche». Die verschiedenen Farben definierten die unterschiedlichen Tage.</P>
            </GridElement>
            <GridElement layout="c2_2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/Insta_2_3_mSZWntuzy.gif" alt="Das Keyvisual"/>
            </GridElement>

            <GridElement layout="c3_1">
              <H3>
                  Inhalt: Konzerte (28%), Partys (19%), Drag (3%),
                  Yoga (3%), Workshops (12%), Flohmi (6%),
                  Kunst (21%), Games (6%), Zusatzstoffe (2%)
              </H3>
            </GridElement>
            <GridElement layout="c3">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/Leporello_OZi04SC67m.jpg" alt="Das Keyvisual"/>
              <P>Neben den Plakaten und den Keyvisuals erstellte ich einen kleinen Leporello-Flyer mit der Programmübersicht der einzelnen Tage.</P>
            </GridElement>
            <GridElement layout="c2_2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/bag-printing-gif_ZX4y0t3Auc1P.webp" alt="Bag Printing"/>
            </GridElement>
            <GridElement layout="c2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/IMG_20190607_110613_Yf3NRfMfPDTT.jpg" alt="Bags trocknen"/>
            </GridElement>
            <GridElement layout="c2_2">
            <P>Von Hand Siebgedruckte Bags waren als Merch erhältlich.</P>
            </GridElement>
            
            
            <GridElement layout="c3_1">
              <P>«Karl hat mit sehr grossem Einsatz und viel Herzblut für die Sommersause 2019 im Werkk Kulturlokal Baden die komplette Grafik (Webauftritt, Plakate, Leporello, Signalisation) gestaltet und bewirtschaftet, sowie mit seinen gestalterischen Ideen den Auftritt des gesamten Festivals geprägt. Er hat das spartenreiche Festival grafisch sehr schön unter einen Hut gebracht, so dass alle Veranstalter, egal welcher Kunst-/Musikrichtung angehörend, begeistert waren und auch von Besuchern viel positives Feedback rückgemeldet wurde.» <br /><small><b>– MADELAINE AMMANN, WERKK</b></small></P>
            </GridElement>
            

               

            {/*<GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/dimmi-posting-mit-bildern_BJato-pQV.jpg" alt="Detailansicht der ersten Seite des Magazins"/>
            </GridElement>

            <GridElement layout="c4">
                <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                  <iframe title="Animiertes Erklärvideo Dimmi" src="https://player.vimeo.com/video/313893788" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </GridElement>*/}
             
            
          </Grid>
        </Container>
    );
  }
}

export default Sommersause;