import React, { Component } from 'react';

import styles from './Header.module.css';
import Img from '../../atoms/Img/Img';

export class Header extends Component {
	render() {
		return (
			<div className={styles.header}>
				<Img src={this.props.img} alt="" /> 
			</div>
		);
	}
}

export default Header