import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class AusstellungWohnenBeWohnen extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img=""/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="Ausstellung wohnen-be-wohnen"
                client="Filzszene Schweiz"
                year="2019"
                competence="Flyer, Plakate, Onlinebanner, Printinserat"
                collaboration={<React.Fragment><A href="https://filzszene.ch/" target="_blank">Filzszene Schweiz</A></React.Fragment>}
              />
            </GridElement>
            <GridElement layout="c2_2">
              <H2>26 Aussteller unter einem Dach.</H2>
            </GridElement><br/>
            <GridElement layout="c3">
              <P>Für die Ausstellung «wohnen-be-wohnen» der Filzszene Schweiz durfte ich Flyer, Plakate und Onlinebanner entwickeln und erstellen.</P>
            </GridElement>
            <GridElement layout="c2_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Wohnen_be_wohnen/flyer-wohnen-be-wohnen_S1l7X2FIE.gif" alt=""/>
            </GridElement>

            <GridElement layout="c2_2">
              <P>Da viele verschiedene Arbeiten von verschiedenen Künstlerinnen ausgestellt werden, habe ich mich dazu entschieden, ein Motiv zu kreieren, welches sich mit dem Thema der Ausstellung und dem Material Filz auseinandersetzt. Anstatt nur ein oder wenige Ausstellungsstücke zu zeigen, sind so alle gleichwertig vertreten.</P>
            </GridElement>
            <GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Wohnen_be_wohnen/neu-angebordnet_Bk5VpnFLN.png" alt="Online Banner für Ausstellung wohnen-be-wohnen"/>
            </GridElement>

           
            <GridElement layout="c3">
              <P>Das Haus welches aus Bauklötzen besteht, welche mit der Struktur des Filzes ausgefüllt sind, nimmt sowohl Bezug auf das Thema der Ausstellung, als auch die verschiedenen Arbeiten die dort präsentiert werden und gemeinsam ein Gesamtkonstrukt bilden.</P>
            </GridElement>

            <GridElement layout="c2_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Wohnen_be_wohnen/animation_001_SJhY_2FLN.gif" alt="Online Banner für Ausstellung wohnen-be-wohnen"/>
            </GridElement>

            {/*<GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/dimmi-posting-mit-bildern_BJato-pQV.jpg" alt="Detailansicht der ersten Seite des Magazins"/>
            </GridElement>

            <GridElement layout="c4">
                <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                  <iframe title="Animiertes Erklärvideo Dimmi" src="https://player.vimeo.com/video/313893788" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </GridElement>*/}
             <GridElement layout="c1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Wohnen_be_wohnen/plakat_rkhYWTK8V.png" alt="Online Banner für Ausstellung wohnen-be-wohnen"/>
            </GridElement>
            <GridElement layout="c3_1">
              <P>Insgesamt wurden 2500 Flyer und 60 Plakate gedruckt. Ein Inserat mit dem Keyvisual wurde zusätlich in einer Fachzeitschrift veröffentlicht. Der Onlinebanner kommt auf der Webseite der Filzszene Schweiz zum Einsatz.</P>
            </GridElement>
            
            
          </Grid>
        </Container>
    );
  }
}

export default AusstellungWohnenBeWohnen;