import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class AnimationsvideoDimmi extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/HeaderImg-breit_B1jInlWEN.jpg"/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="Dimmi-Erklärvideo"
                client="Migros-Genossenschafts-Bund"
                year="2018"
                competence="Animiertes Video"
                collaboration={<React.Fragment>Während meiner Ausbildung bei <br /><A href="http://migros.ch" target="_blank">Migros-Genossenschafts-Bund</A></React.Fragment>}
              />
            </GridElement>
            <GridElement layout="c2_2">
              <H2>«Wie geht dieses neue Dimmi?»</H2>
            </GridElement><br/>
            <GridElement layout="c3">
              <P>Für den Launch des internen Social Networks «Dimmi» der Migros-Gruppe durfte ich ein animiertes Erklärvideo erstellen.</P>
            </GridElement>

            <GridElement layout="c2_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/Bildschirmfoto_2019-01-29_um_00.10.24_rJpxn-TQE.png" alt="AlPride Spotify Codes im Magazin"/>
            </GridElement>

            <GridElement layout="c2_2">
              <P>Das Ziel des Videos war es, den über 100000 potenziellen Benutzern aus 50 unterschiedlichen Unternehmen der Migros-Gruppe das neue soziale Netzwerk vorzustellen und kurz zu erklären.</P>
            </GridElement>

            <GridElement layout="c3">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/dimmi-vorher-nacher_B1kiqWTQ4.jpg" alt="AlPride in Zahlen Detail aus dem Magazin"/>
            </GridElement>
            <GridElement layout="c3_1">
              <P>Um den Betrachter, welcher vielleicht zum ersten mal mit dem sozialen Netzwerk in Kontakt kommt, nicht zu überfordern, habe ich alle im Video sichtbaren Screens mit Adobe Illustrator nachgebaut. Unwichtige Informationen wie Gruppenbeschreibungen, Profilbilder oder Namen habe ich dabei weggelassen und dadurch den Fokus aufs wesentliche gelenkt.</P>
            </GridElement>         

            {/*<GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/dimmi-posting-mit-bildern_BJato-pQV.jpg" alt="Detailansicht der ersten Seite des Magazins"/>
            </GridElement>*/}

            <GridElement layout="c4">
                <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                  <iframe title="Animiertes Erklärvideo Dimmi" src="https://player.vimeo.com/video/313893788" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </GridElement>
            <GridElement layout="c4">
              <P>Das Video wurde ausserdem ins Italienische und Französische übersetzt.<br/> Neben dem Erstellen des Videos war ich auch in die Entwicklung des Social Networks als Tester involviert.
              <br />Programmierung Dimmi: <A href="https://smartive.ch/cases/migros-dimmi" target="_blank">Smartive</A>
              <br />Alle Rechte: <A href="https://migros.ch" target="_blank">Migros-Genossenschafts-Bund</A></P>
            </GridElement>
            
            
          </Grid>
        </Container>
    );
  }
}

export default AnimationsvideoDimmi;