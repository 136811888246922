import React, { Component } from 'react';

import styles from './H4.module.css';

export class H4 extends Component {
	render() {
		return (
			<h4 className={styles.title_h4}>{this.props.children}</h4>
		);
	}
}

export default H4