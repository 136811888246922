import React, { Component } from 'react';

import styles from './P.module.css';

export class P extends Component {
	render() {
		return (
			<p className={styles.text_p}>{this.props.children}</p>
		);
	}
}


export default P 