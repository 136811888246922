import React, { Component } from 'react';

import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class BandportraitAlPride extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/Bandportrait_Al_Pride/al-pride-coverimage_rkF1pzr74.jpg"/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="Bandportrait Al Pride"
                client="Abschlussarbeit"
                year="2018"
                competence="Print, Video"
                collaboration={<React.Fragment>Abschlussarbeit <br />Mit <A href="http://philipp.pe" target="_blank">Philipp</A> & <A href="http://lars-widmer.ch" target="_blank">Lars</A></React.Fragment>}
              />
            </GridElement>
            <GridElement layout="c3">
              <P>Das Bandportrait über Al Pride habe ich gemeinsam mit Philipp Persano und Lars Widmer als Abschlussarbeit in den Fächern Multimediatechnik und Gestaltung & Design erstellt.</P>
            </GridElement>
            
            <GridElement layout="c2_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Bandportrait_Al_Pride/al-pride-cover-closeup_ryIlnMB74.jpg" alt="Detailansicht der ersten Seite des Magazins"/>
            </GridElement>

            <GridElement layout="c2_2">
              <P>Die Abschlussarbeit besteht aus einem Magazin und einem Video über die Band. Das Magazin ist inhaltlich eine verkürzte Version des Videos und sollte den Leser dazu motivieren, sich auch das Video anzusehen.</P>
            </GridElement>

            <GridElement layout="c3">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Bandportrait_Al_Pride/al-pride-in-zahlen_rkjJnGrXV.jpg" alt="AlPride in Zahlen Detail aus dem Magazin"/>
            </GridElement>
            <GridElement layout="c2_1">
              <P>Gedruckt haben wir das Magazin mit einem Risographen welcher sich optisch vor allem durch die Farben und sein Druckbild auszeichnet.</P>
            </GridElement>         

            <GridElement layout="c2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Bandportrait_Al_Pride/al-pride-spotify-code_HksyhzHX4.jpg" alt="AlPride Spotify Codes im Magazin"/>
            </GridElement>
            <GridElement layout="c3">
              <P>An den relevanten Stellen wurden Spotify-Codes platziert welche mittels Spotify-App gescannt werden können und auf den entsprechenden Song verlinken.</P>
            </GridElement>
            

             <GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Bandportrait_Al_Pride/al-pride-nfc-tags_S1llhMHmN.jpg" alt="Smartphone NFC Tag"/>
            </GridElement>
            <GridElement layout="c2_2">
              <P>Die Verlinkung zwischen online und offline haben wir mit einem NFC-Tag gelöst. Dieser kann ganz einfach mit dem Smartphone gescannt werden.</P>
            </GridElement>

            
            <GridElement layout="c2">
              <P><A target="_blank" href="https://issuu.com/karl-hofmann/docs/printprodukt_normaldruck">Hier</A> gehts zum ganzen Magazin.</P>
            </GridElement>

            <GridElement layout="c4">
                <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                  <iframe title="Video Al Pride" src="https://player.vimeo.com/video/275230889" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </GridElement>

            <GridElement layout="c3_1">
              <H2>«Musik ist eine Sprache, die mehr ausdrücken kann als Worte. Jeder Mensch versteht sie.»</H2>
            </GridElement><br/>
          </Grid>
        </Container>
    );
  }
}

export default BandportraitAlPride;