import React, { Component } from 'react';

import Container from '../../components/atoms/Container/Container';
import A from '../../components/atoms/A/A';
import P from '../../components/atoms/P/P';
import H1 from '../../components/atoms/H1/H1';
import H2 from '../../components/atoms/H2/H2';
import H4 from '../../components/atoms/H4/H4';
import Grid from '../../components/atoms/Grid/Grid';
import GridElement from '../../components/atoms/GridElement/GridElement';

import styles from './Home.module.css';

export class Home extends Component {
	render() {
		return (
			<Container>
				<Grid>
					<GridElement layout=""></GridElement>					
					<GridElement layout="c4">
						<H1>Karl <br/>Hofmann</H1>
						<P>Grafik für <span className={styles.koslink}><A href="https://kosmos.ch" target="_blank">KOS<span className={styles.m}>M</span>OS</A></span><br />
						Video & Design bei <A href="https://backonearth.ch">BACK ON EARTH</A></P>
					</GridElement>		
					<GridElement layout="c4">
						
						<H2>
						<A type="internal" href="/portfolio/">
							Portfolio
						</A><br/>
						<A type="internal" href="/ueber-mich/">
							Über mich
						</A>
						</H2>
					</GridElement>
					<GridElement layout="c4">
						
					</GridElement>
					<GridElement layout="c1">
						<A href="mailto:karl@kaffeekraft.ch" target="_blank">
							<H4>Email</H4>
						</A>
						<A href="https://www.linkedin.com/in/k-hofmann" target="_blank">
							<H4>linkedin</H4>
						</A>
						<A href="https://issuu.com/karl-hofmann" target="_blank">
							<H4>ISUU</H4>
						</A>
					</GridElement>
					
					<GridElement layout="c1_4">
						
					</GridElement>
				</Grid>
			</Container>
		);
	}
}

export default Home