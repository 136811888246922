import React, { Component } from 'react';

import styles from './Footer.module.css';

import P from '../../atoms/P/P'

import CoffeeLove from '../../../assets/coffee-love.svg';

export class Footer extends Component {
	render() {
		return (
			<div className={styles.footer}>
				<img className={styles.icon} src={CoffeeLove} alt=""/>
				<P><small>Kaffeekraft.</small></P>
			</div>
		);
	}
}

export default Footer