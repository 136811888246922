import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import H3 from '../../../components/atoms/H3/H3';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class Revalur extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/Revalur/revaklur-header__9vOlmMP9.jpg"/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="Branding Revalur"
                client="Revalur"
                year="2020"
                competence="Branding"
                collaboration={<React.Fragment><A href="https://revalur.ch" target="_blank">Revalur</A></React.Fragment>}
              />
            </GridElement>
            <GridElement layout="c2_1">
              <H2>Unkomplizierte Entsorgung für die Schweiz</H2>
            </GridElement>
            <GridElement layout="c3">
              <P>Revalur ist ein Startup welches sich zum Ziel gemacht hat die Abfallflüsse der Schweiz zu digitalisieren. Ich durfte für sie das Branding erstellen. </P>
            </GridElement>
            <GridElement layout="c2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Revalur/revalur-logo_gYE5GhjJ5" alt="Revalur Logo" />
            </GridElement>
            <GridElement layout="c2_2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Revalur/revalur-kleines-logo_aVKQiLWW7Hfl" alt="Revalur Kleines Logo" />
            </GridElement>

            <GridElement layout="c4">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Revalur/Muster_TT3jPdOgkl4FN" alt="Revalur Kleines Logo" />
            </GridElement>
            
            <GridElement layout="c2_1">
              <P>Ein einfaches Gestaltungselement, abgeleitet aus dem Logo, ergibt ein richtungsweisendes Element welches vielseitig einsetzbar ist.</P>
            </GridElement>
            
            <GridElement layout="c2_2">
              <P>Entstanden ist ein simples und vielseitig einsetzbares Branding welches zu einem späteren Zeitpunkt gut erweitert werden kann.</P>
            </GridElement>
            

               

            {/*<GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/dimmi-posting-mit-bildern_BJato-pQV.jpg" alt="Detailansicht der ersten Seite des Magazins"/>
            </GridElement>

            <GridElement layout="c4">
                <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                  <iframe title="Animiertes Erklärvideo Dimmi" src="https://player.vimeo.com/video/313893788" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </GridElement>*/}
             
            
          </Grid>
        </Container>
    );
  }
}

export default Revalur;