import React, { Component } from 'react';
import axios from 'axios';

import styles from './ChatBot.module.css';

import P from '../../atoms/P/P';
import GridElement from '../../atoms/GridElement/GridElement';

import Chatbot from '../../../assets/chatbot.svg';

export class ChatBot extends Component {
	ChangeHandler = (e) => {
    	this.setState({[e.target.id]: e.target.value})
    }

	handleMessageRequest = (e) => {
		e.preventDefault();
		let query = this.state.userInput;

		this.setState({
			requested: true,
			query: query,
			userInput: "",
			showOnboarding: false,
		})

		
		axios.get('https://dialogflow.googleapis.com/v2/projects/karl-bot/agent/sessions/b959037e-cb90-1fee-fd79-5b6c9b12b4de:detectIntent', {
			params:  {
			"queryInput": {
				"text": {
					"text": "hoi",
					"languageCode":"de"
				}}, "queryParams": {
					"timeZone":"Europe/Zurich"
				}},
		    headers: {
		    	"Authorization": "Bearer ya29.c.Kl6xB-trADaZBbjlCsvIR7JGxfe_h6owepHbQIkbCLd5aakDBgmPjkxDssGC_yP9EAdImvfS5br-3ORRDWPse4w_GSgRiwho0UspVF66b7UyAn5LrP7dGEv_BWQG9Tfl"
		    }
		  })

			.then(response => this.setState({ response: response.data.result.speech}))
		  
			.catch(error => {
				console.log(error);
				this.setState({ response: "Sorry. Ich kann deine Anfrage momentan nicht verarbeiten. "+error})
	
			})
			.then(function () {
		    	// always executed
		    });  
	}
	
	state = {
		response: "",
		userInput: "",
		requested: false,
		sessionId: Date.now(),
		showOnboarding: true,
	}

	render() {
		let onBoard = ""
		if (this.state.showOnboarding) {
			onBoard = <img className={styles.icon} src={Chatbot} alt=""/>
		} else {
			onBoard = ""
		}
		return (
			<React.Fragment>

				<GridElement layout="c2_1">
					<span className={styles.importantRight}><P>{this.state.requested ? this.state.query : ""}</P></span>
					
				</GridElement>
				<GridElement layout="c2">
					<P>{this.state.response}</P>
				</GridElement>

				<GridElement layout="c4">
				{onBoard}
					<form onSubmit={this.handleMessageRequest} action="#" className={styles.form}>
						<input className={styles.inputText} type="text" value={this.state.userInput} onChange={this.ChangeHandler} name="userInput" id="userInput"/>
						<input className={styles.inputSubmit} type="submit" />
					</form>
				</GridElement>
			</React.Fragment>
		);
	}
}

export default ChatBot