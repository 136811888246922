import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import H3 from '../../../components/atoms/H3/H3';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class ErzAwarenessvideos extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/ERZ_Awarenessvideos/erz_awarenessvideo-header_GGLRhcgHfaZ5.jpg"/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="ERZ Awarenessvideos"
                client="Revalur"
                year="2020"
                competence="Branding"
                collaboration={<React.Fragment><A href="https://www.stadt-zuerich.ch/ted/de/index/entsorgung_recycling.html" target="_blank">Stadt Zürich</A></React.Fragment>}
              />
            </GridElement>
            <GridElement layout="c2_1">
              <H2>Wir lieben Zürich sauber.</H2>
            </GridElement>
            <GridElement layout="c3">
              <P>Für die Stadt Zürich konzipierten und produzierten wir (back on earth gmbh) fünf Werbespots, die auf humorvolle Art und Weise ein grösseres Bewusstsein für Entsorgungsthemen schaffen.</P>
            </GridElement>
            <GridElement layout="c3_1">
              <P>Unser Protagonist ist darin nämlich ziemlich ungeschickt. Wir begleiten ihn und seine Freundin durch die verschiedensten Alltagssituationen, die regelmässig im Chaos enden. Dabei wäre es so simpel, unsere Stadt sauber zu halten.</P>
            </GridElement>
            <GridElement layout="c4">
              <div Style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/472287432" frameborder="0" allow="autoplay; fullscreen" allowfullscreen Style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
            </GridElement>
            <GridElement layout="c2">
              <div Style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/473772552" frameborder="0" allow="autoplay; fullscreen" allowfullscreen Style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
            </GridElement>
            <GridElement layout="c2_2">
              <div Style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/476635351" frameborder="0" allow="autoplay; fullscreen" allowfullscreen Style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
            </GridElement>
            <GridElement layout="c2">
              <div Style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/477958779" frameborder="0" allow="autoplay; fullscreen" allowfullscreen Style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
            </GridElement>
            <GridElement layout="c2_2">
              <div Style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/488575824" frameborder="0" allow="autoplay; fullscreen" allowfullscreen Style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
            </GridElement> 
            
          </Grid>
        </Container>
    );
  }
}

export default ErzAwarenessvideos;