import React, { Component } from 'react';

import Container from '../../components/atoms/Container/Container';
import A from '../../components/atoms/A/A';
import H1 from '../../components/atoms/H1/H1';
import H3 from '../../components/atoms/H3/H3';
import H4 from '../../components/atoms/H4/H4';
import Img from '../../components/atoms/Img/Img';
import Grid from '../../components/atoms/Grid/Grid';
import GridElement from '../../components/atoms/GridElement/GridElement';

export class Portfolio extends Component {
	render() {
		return (
			<Container>
				<Grid>
					<GridElement layout="c4">
						<H1>Portfolio</H1>
					</GridElement>

					<GridElement layout="c4">
						<A type="internal" href="/portfolio/kei-party-bier/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/header-bild-kei-party_fNzrNjGx-Lgs.jpg" alt="Kei Party Bier für die badener Clubs" />
							<H3>Kei Party Bier</H3>
						</A>
					</GridElement>
					<GridElement layout="c2">
						<A type="internal" href="/portfolio/erz-awarenessvideos/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/ERZ_Awarenessvideos/erz_awarenessvideo-header_GGLRhcgHfaZ5.jpg" alt="ERZ Awarenessvideos" />
							<H3>Awarenessvideos ERZ</H3>
						</A>
					</GridElement>
					<GridElement layout="c2_2">
						<A type="internal" href="/portfolio/personalisierte-videos/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Personalisierte_Videos/personalisierte-videos-header_2Etls70PL?ik-sdk-version=javascript-1.4.3&updatedAt=1656160971116" alt="ERZ Awarenessvideos" />
							<H3>Personalisierte Videos</H3>
						</A>
					</GridElement>
					<GridElement layout="c2">
						<A type="internal" href="/portfolio/revalur/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Revalur/revaklur-header__9vOlmMP9.jpg" alt="" />
							<H3>Revalur</H3>
						</A>
					</GridElement>
					<GridElement layout="c2_2">
						<A type="internal" href="/portfolio/sommersause/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/werkk-sommersause-header_PP4jgLRFp" alt="WERKK Kulturlokal Sommersause" />
							<H3>WERKK Sommersause</H3>
						</A>
					</GridElement>
					<GridElement layout="c2">
						<A type="internal" href="/portfolio/bandportrait-al-pride/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Bandportrait_Al_Pride/al-pride-coverimage_rkF1pzr74.jpg" alt="Bandportrait über Al Pride" />
							<H4>Bandportrait Al Pride</H4>
						</A>
					</GridElement>
					<GridElement layout="c2_2">
						<A type="internal" href="/portfolio/wassermarke-libertas/">
							<Img src="https://ik.imagekit.io/kaffeekraft/libertas-thumbnail_HJ4CdEnNE.jpg" tr="ar-2-1" alt="Bandportrait über Al Pride" />
							<H4>Wassermarke Libertas</H4>
						</A>
					</GridElement>
					{/*<GridElement layout="c1_2">
						<A type="internal" href="/portfolio/kursprogramm-filzquadrat/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Kursprogramm_Filzquadrat/filzquadrat-kursprogramm-covergross_Hy7jLUI7N.jpg" tr="ar-1.6-1" alt="Kursprogramm Filzquadrat" />
							<H4>Kursprogramm Filzquadrat</H4>
						</A>
					</GridElement>
					<GridElement layout="c2_2">
						<A type="internal" href="/portfolio/ausstellung-wohnen-be-wohnen/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Wohnen_be_wohnen/Twitter_Beitragsbild_rygYE6FUN.png" alt="ausstellung-wohnen-be-wohnen" />
							<H3>Ausstellung wohnen-be-wohnen</H3>
						</A>
					</GridElement>
					<GridElement layout="c1_3">
						<A type="internal" href="/portfolio/animationsvideo-dimmi/">
							<Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/dimmi-thumb_BkWupeWVN.jpg" tr="ar-1.6-1" alt="Animationsvideo Dimmi" />
							<H4>Erklärvideo Dimmi</H4>
						</A>
					</GridElement>*/}		
				</Grid>
			</Container>
		);
	}
}

export default Portfolio