import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class WassermarkeLibertas extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-uebersicht_rk7q9NcVN.png"/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="Wassermarke Libertas"
                client="Migros-Genossenschafts-Bund"
                year="2018"
                competence="Konzept, div. Design"
                collaboration={<React.Fragment>Während meiner Ausbildung bei <br /><A href="http://migros.ch" target="_blank">Migros-Genossenschafts-Bund</A></React.Fragment>}
              />
            </GridElement>
            <GridElement layout="c2">
              <H2>«Eine Legende vom Thunersee.»</H2>
            </GridElement><br/>
            <GridElement layout="c2_2">
              <P>Zur Übung habe ich den visuellen Auftritt der Wassermarke «Libertas» erstellt.</P>
            </GridElement>

            
            <GridElement layout="c4">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-formkonzept_SJWMxlaXN.png" alt="Das Farbkonzept von Libertas"/>
              <br/><br/>
            </GridElement>

            <GridElement layout="c3_1">
              <P>Die Werte welche Libertas vertritt, werden in den Formen stark vereinfacht wiedergespiegelt. Eingesetzt als Muster bietet es vielfältige Einsatzmöglichkeiten.</P>
            </GridElement>

            <GridElement layout="c2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-farbkonzept_S1G_xeaQV.jpg" alt="Das Formkonzept von Libertas"/>
            </GridElement>

            <GridElement layout="c2_2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-logo-mit-claim_rJzegg6mE.jpg" alt="Das Logo von Libertas"/>
            </GridElement>

            <GridElement layout="c2_1">
              <P>Das Konzept berücksichtigt Logo, Farbe, Form, Bild und Typografie. </P>
            </GridElement>

            <GridElement layout="c1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-rollup-banner_rkBlll6XV.png" tr="" alt="Libertas Flaschen Mockups"/>
            </GridElement>
            <GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-flaschenetiketten-mockup_rJzlgxam4.png" alt="Libertas Flaschen Mockups"/>
            </GridElement>
            
            <GridElement layout="c4">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas_etikette-still_SJZggxTmV.png" alt="Libertas Strassenplakat"/>
            </GridElement>
            
            <GridElement layout="c4">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-etiketten-prickelnd-legere_r1WggeamN.png" alt="Libertas Strassenplakat"/>
            </GridElement>

            <GridElement layout="c2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-strassenplakat_HyxZex6X4.png" alt="Libertas Strassenplakat"/>
            </GridElement>
            
            <GridElement layout="c2_2">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Libertas/libertas-lastwagen-gestaltung_SJclelp74.png" alt="Libertas Lieferwagen"/>
            </GridElement>

            
            <GridElement layout="c3_1">
              <P>Abgeleitet vom Konzept habe ich verschiedene Mockups und Produkte gestaltet.</P>
            </GridElement>

            <GridElement layout="c3_1">
              <P></P>
            </GridElement>         

            {/*<GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/dimmi-posting-mit-bildern_BJato-pQV.jpg" alt="Detailansicht der ersten Seite des Magazins"/>
            </GridElement>*/}

            <GridElement layout="c4">
              <P>Alle Rechte: <A href="https://migros.ch" target="_blank">Migros-Genossenschafts-Bund</A></P>
            </GridElement>
            
            
          </Grid>
        </Container>
    );
  }
}

export default WassermarkeLibertas;