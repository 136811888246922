import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';

import styles from './Img.module.css';

export class Img extends Component {
	handleLoad = () => {
		let src = this.props.src
    	document.getElementById(src).style.filter="none"
    	document.getElementById(src).style.backgroundImage="none"
  	}

	render() {
		let src = this.props.src
		let tr = (this.props.tr ? this.props.tr : null)
		let backgroundImage = "url("+src+"?tr=" + tr + ",w-150,e-grayscale,q-30)"
		let srcset = src + "?tr=" + tr + ",w-500 500w," +
	            	 src + "?tr=" + tr + ",w-800 800w," +
	            	 src + "?tr=" + tr + ",w-1000 1000w," +
	            	 src + "?tr=" + tr + ",w-1200 1200w," +
	            	 src + "?tr=" + tr + ",w-1500 1500w," +
	            	 src + "?tr=" + tr + ",w-1700 1700w," +
	            	 src + "?tr=" + tr + ",w-1900 1900w," +
	            	 src + "?tr=" + tr + ",w-2000 2000w,"
		return (
			<div style={{backgroundImage: backgroundImage}} id={src} className={styles.imageContainer}>
				<LazyLoad >
					<img className={styles.img} onLoad={this.handleLoad} alt={this.props.alt} srcSet={srcset} src={src}/>
				</LazyLoad>
			</div> 
		);
	}
}

export default Img