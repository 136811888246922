import React, { Component } from 'react';

import styles from './LogoHeader.module.css';

import Logo from '../../../assets/logo-kaffeekraft.svg';
import H4 from '../../atoms/H4/H4';
import A from '../../atoms/A/A';
import Container from '../../atoms/Container/Container';

export class LogoHeader extends Component {
		state = ({
			opened: false
		})

		handleClick = () => {
			if (this.state.opened) {
				document.getElementById('navigation').style.height="0px";
				document.getElementById('navigation').style.opacity="0";
				document.getElementById('navigation').style.visibility="hidden";
				this.setState({
					opened: false
				})
			} else{
				document.getElementById('navigation').style.height="85px";
				document.getElementById('navigation').style.opacity="1";
				document.getElementById('navigation').style.visibility="initial";
				this.setState({
					opened: true
				}, () => {
					setTimeout(()=>{this.handleClick()}, 10000);
					
				});	
			}
		}

		render() {
			return (
			<Container>
				<div className={styles.logoContainer}>
					<img src={Logo} className={styles.logo} onClick={this.handleClick} alt="Logo Kaffeekraft"/>
					<div className={styles.navigation} id="navigation" style={{height: 0, opacity: 0}}>
						<A href="/" type="internal"><H4>Home</H4></A>
						<A href="/portfolio" type="internal"><H4>Portfolio</H4></A>
						<A href="/ueber-mich" type="internal"><H4>Über mich</H4></A>
					</div>
				</div>
			</Container>
		);
	}
}

export default LogoHeader
