import React, { Component } from 'react';

import styles from './H2.module.css';

export class H2 extends Component {
	render() {
		return (
			<h2 className={styles.title_h2}>{this.props.children}</h2>
		);
	}
}

export default H2