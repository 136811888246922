import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import H3 from '../../../components/atoms/H3/H3';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class personalisierteVideos extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/Personalisierte_Videos/personalisierte-videos-header_tIHbBbovi?ik-sdk-version=javascript-1.4.3&updatedAt=1656160830263  "/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="Personalisierte Videos"
                client="ZLI"
                year="2022"
                competence="Video & Programmierung"
                collaboration={<React.Fragment><A href="https://www.zli.ch/" target="_blank">Zürcher Lehrbetriebsverband ICT</A></React.Fragment>}
              />
            </GridElement>
           
            <GridElement layout="c2">
              <H2>Automatisch & persönlich.</H2>
            </GridElement>
            <GridElement layout="c3">
              <P>Für den Zürcher Lehrbetriebsverband ICT konzipierten und realisierten wir (back on earth gmbh) für die diesjährigen Absolvent*innen personalisierte Videos.</P>
            </GridElement>
             <GridElement layout="c4">
              <div Style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/586751385" title="Vimeo" frameborder="0" allow="autoplay; fullscreen" allowfullscreen Style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
            </GridElement>
            <GridElement layout="c3">
              <P>Die Lehrabgänger*innen konnten selbst ein Bild hochladen und kurze Zeit später hatten Sie bereits ihr ganz persönliches Video auf dem Smartphone. Bereit, mit Familie, Freunden und auf Social Media geteilt zu werden.</P>
            </GridElement>
            <GridElement layout="c3_1">
              <P>Die Videos basieren auf einem Template und werden angereichert mit bestehenden Daten der Lernenden und einem vom User hochgeladenen Bild. Einmal eingerichtet läuft das Rendering sowie das Versenden der E-Mails vollständig autonom. Das Rendering wird verteilt auf mehrere Server und kann so sehr leicht skaliert werden.</P>
            </GridElement>
            
          </Grid>
        </Container>
    );
  }
}

export default personalisierteVideos;