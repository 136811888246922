import React, { Component } from 'react';

import { Link } from "react-router-dom";

import styles from './A.module.css'

export class A extends Component {
	render() {
		if (this.props.type === 'internal') {
			return (<Link to={this.props.href} className={styles.a}>{this.props.children}</Link>)

		} else {
			return (<a className={styles.a} href={this.props.href} target={this.props.target} rel="noopener noreferrer">{this.props.children}</a>)

		}
	}
}

export default A