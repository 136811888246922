import React, { Component } from 'react';


import Container from '../../../components/atoms/Container/Container';
import P from '../../../components/atoms/P/P';
import H2 from '../../../components/atoms/H2/H2';
import H3 from '../../../components/atoms/H3/H3';
import A from '../../../components/atoms/A/A';
import Grid from '../../../components/atoms/Grid/Grid';
import GridElement from '../../../components/atoms/GridElement/GridElement';
import Img from '../../../components/atoms/Img/Img';
import Header from '../../../components/molecules/Header/Header';

import ProjectTable from '../../../components/molecules/ProjectTable/ProjectTable';

class KeiPartyBier extends Component {
  render() {
    return (
        <Container>
          <Grid>
            <GridElement layout="c4">
              <Header img="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/header-bild-kei-party_fNzrNjGx-Lgs.jpg"/>
            </GridElement>
            <GridElement layout="c4">
              <ProjectTable 
                title="Kei Party Bier"
                client="Die Badener Clubs"
                year="2020"
                competence="Branding, Packaging, Online"
                collaboration={<React.Fragment><A href="https://werkk-baden.ch" target="_blank">Die Badener Clubs</A></React.Fragment>}
              />
            </GridElement>
            <GridElement layout="c2_1">
              <H2>Clubs zu, <br/>dose auf.</H2>
            </GridElement>
            <GridElement layout="c3">
              <P>Die Badener Clubs Cordula Club, Gate54, LWB, Nordportal, Royal und Werkk spannen erstmals in ihrer Geschichte zusammen, um ihren Gästen, ein Lebenszeichen aus dem Lockdown zu schicken.</P>
            </GridElement>

            <GridElement layout="c2_2">
              <P>Was als waschechte Bieridee entstand, wurde in nur zwei Wochen zur Realität. Neben dem Design der Dose und Social Media Content setzte ich für die Initiative eine kleine Landingpage inkl. Bestellsystem und Wall of Fame um.</P>
            </GridElement>

            <GridElement layout="c4"> 
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/Dose_rendering_3er-Kombi_SEoObjie5PQA.jpg" alt=""/>
            </GridElement>

             <GridElement layout="c3">
              <H2>Hold my beer.</H2>
            </GridElement>
            <GridElement layout="c1"> 
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/story_0Irl0Hhg_AUd.jpg" alt=""/>
            </GridElement>
            <GridElement layout="c1_1"> 
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/story_2_dVvO63FcvujA8.jpg" alt=""/>
            </GridElement>
            <GridElement layout="c1_2"> 
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/story_3_an8gWZha0D5Ni.jpg" alt=""/>
            </GridElement>
            <GridElement layout="c1_3"> 
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/story_4_2uc56jy9hVFPW.jpg" alt=""/>
            </GridElement>
            <GridElement layout="c1_1"> 
              <P>Und hier noch ein Bild wie ich selbst «mein Bier» halte:</P>
            </GridElement>
            <GridElement layout="c1_2"> 
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Sommersause/barkeeper-und-grafiker-karl-hofmann_sb0ecPaMLRPg.jpg" alt=""/>
            </GridElement>
            
            <GridElement layout="c3">
              <P>Gebraut und abgefüllt wurde das Bier von der lokalen Craftbier-Brauerei MischMasch. Und es schmeckt Badener*innen: Schon nach wenigen Tagen sind die Dosen ausverkauft. Es wird fleissig nachproduziert. Am schluss werden ca. 3700 Dosen verkauft. </P>
            </GridElement>

            <GridElement layout="c4">
              <P><A target="_blank" href="https://www.nau.ch/ort/baden/badener-trinken-bier-aus-solidaritat-fur-die-clubs-65709902">Bericht auf Nau.ch</A>, <A target="_blank" href="https://www.badenertagblatt.ch/aargau/baden/solidaritatsbier-wider-den-party-kater-ld.1222815">Bericht von Badener Tagblatt</A>, </P>
            </GridElement>
            {/*<GridElement layout="c3_1">
              <Img src="https://ik.imagekit.io/kaffeekraft/Portfolio/Dimmi_Erkl_rvideo/dimmi-posting-mit-bildern_BJato-pQV.jpg" alt="Detailansicht der ersten Seite des Magazins"/>
            </GridElement>

            <GridElement layout="c4">
                <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                  <iframe title="Animiertes Erklärvideo Dimmi" src="https://player.vimeo.com/video/313893788" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </GridElement>*/}
             
            
          </Grid>
        </Container>
    );
  }
}

export default KeiPartyBier;